import React, { useCallback, useContext, useEffect, useState } from "react";

import PaymentIcon from "@mui/icons-material/Payment";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import DescriptionIcon from "@mui/icons-material/Description";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

import {
  Box,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  LinearProgress,
  Radio,
  Step,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from "@mui/material";

import { NavLink } from "react-router-dom";

import { PartsContext } from "../../context/PartsContext";
import PartCartItem from "../warrantyManagement/parts/PartCartItem";
import PortfolioCartItem from "../portfolioCart/PortfolioCartItem";
import { shoppingCartList } from "../../navigation/navigationConst";
import { removeCart } from "../../services/portfolioServices";
import CustomizedSnackbar from "../../shared/CustomSnackBar";
import NumberSpinner from "../../shared/NumberSpinner";
import { getQuoteCommonConfig } from "../../services/quoteService";
import CustomerOrderAddress from "../shoppingCart/CustomerOrderAddress";
import CustomerOrderPayment from "../shoppingCart/CustomerOrderPayment";
import OrderPayment from "../shoppingCart/OrderPayment";
import OrderSummaryModal from "./OrderSummaryModal";
import PartCartItemDetails from "../carts/PartCartItemDetails";
import PartItemDetails from "../warrantyManagement/parts/PartItemDetails";
import Select from "react-select";
import { FONT_STYLE_SELECT, GRID_STYLE } from "../common/constants";
import { AuthContext } from "../../context/AuthContext";
import { CheckBox } from "@mui/icons-material";
import { getCartParts } from "../../services/partsServices";
import Swal from "sweetalert2";
import { callDeleteApi, callPostApi, callPutApi } from "../../services/ApiCaller";
import { API_SUCCESS } from "../../services/ResponseCode";
import {
  PARTS_ADD_QUANTITY_TO_PARTS_CART_POST,
  PARTS_DELETE_PARTS_FROM_CART_DELETE,
  PARTS_ORDER_MASTER_URL,
  RAZORPAY_ORDER_CREATE_POST,
} from "../../services/CONSTANTS";
import {
  billingTypesOptions,
  deliveryPriorityOptions,
  deliveryTypeOptions,
  orderTypeOptions,
  partOrderObj,
  paymentTermsOptions,
  razorPayOrderObj,
  shippingCondition,
  shippingPointOptions,
  storeOptions,
} from "./orderConstents";
import OrderShippingDetails from "./OrderShippingDetails";
import OrderBillingDetails from "./OrderBillingDetails";
import { DataGrid } from "@mui/x-data-grid";
import CartPriceCard from "./CartPriceCard";
import OrderShippingDtlsCard from "./OrderShippingDtlsCard";
import OrderBillingDtlsCard from "./OrderBillingDtlsCard";
import OrderInformation from "./OrderInformation";
import OrderInfoCard from "./OrderInfoCard";
import { currencyFormatter } from "../common/utils/currencyFormatter";
import axios from "axios";
import Moment from "react-moment";
import PortfolioCartItems from "../warrantyManagement/portfolio/PortfolioCartItems";
import { createRozerPayOrder } from "services/CartOrderServices";
import PaymentSuccess from "./PaymentSuccess";

const cartOrderSteps = ["Shopping Cart", "Shipping & Billing", "Payment", "Order Summary", "Order Confirmation"];

const deleteConfirmationModal = async (cartType = "part", name, rUrl, handleSnack, getCartItems, cartItems, setCartItems, partsId) => {
  try {
    let textValue = cartType === "part" ? `Part Number ${name}` : `Portfolio ${name}`;
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${textValue}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi(
          null,
          rUrl,
          (response) => {
            if (response.status === API_SUCCESS) {
              let _cartItems = [...cartItems["parts"]];
              _cartItems = _cartItems.filter((obj) => obj.partsId !== partsId);
              setCartItems({ ...cartItems, parts: [..._cartItems] });
              handleSnack("success", `${textValue} removed from cart successfully.`);
              getCartItems();
              return true;
            } else {
              handleSnack("info", `Failed to remove item.`);
              return false;
            }
          },
          (error) => {
            handleSnack("info", `Failed to remove item.`);
            return false;
          }
        );
      } else {
        handleSnack("info", "Item removal canceled.");
        return false;
      }
    });
  } catch (error) {
    handleSnack("error", "An error occurred while removing the item.");
    return false;
  }
};

const deleteAllCartItemsModal = async (cartType = "part", rUrl, handleSnack, getCartItems) => {
  try {
    // let textValue = cartType === "part" ? `Part Number ${name}` : `Portfolio ${name}`;
    let textValue = "all Items";
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${textValue}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        callDeleteApi(
          null,
          rUrl,
          (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("success", `${textValue} removed from cart successfully.`);
              getCartItems();
              return true;
            } else {
              handleSnack("info", `Failed to remove item.`);
              return false;
            }
          },
          (error) => {
            handleSnack("info", `Failed to remove item.`);
            return false;
          }
        );
      } else {
        handleSnack("info", "Item removal canceled.");
        return false;
      }
    });
  } catch (error) {
    handleSnack("error", "An error occurred while removing the item.");
    return false;
  }
};

const ProgressBar = ({ progress }) => {
  return (
    <LinearProgress
      variant="determinate"
      value={progress}
      style={{
        height: 10,
        borderRadius: 5,
        backgroundColor: "#e0e0e0", // Background color
      }}
      sx={{
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundColor: "#872ff7", // Purple color
        },
      }}
    />
  );
};

const ReviewCartItem = () => {
  const { portfolioCarts, setPortfolioCarts, cartItems, setCartItems } = useContext(PartsContext);
  const { auth } = useContext(AuthContext);
  const [activeStep, setActiveStep] = useState(0);

  const [count, setCount] = useState(0);
  const [mouseDownDirection, setMouseDownDirection] = useState(null);

  const [portfolioCartNumber, setPortfolioCartNumber] = useState("");
  const [portfolioOrderNumber, setPortfolioOrderNumber] = useState("");

  const [openShippingDtlsModal, setOpenShippingDtlsModal] = useState(false);
  const [openBillingDtlsModal, setOpenBillingDtlsModal] = useState(false);
  const [openOrderInfoModal, setOpenOrderInfoModal] = useState(false);

  const [openNewAddressAddModal, setOpenNewAddressAddModal] = useState(false);
  const [openNewPaymentAddModal, setOpenNewPaymentAddModal] = useState(false);

  const [openPartItemDtlModl, setOpenPartItemDtlModl] = useState(false);
  const [openPartRow, setOpenPartRow] = useState(null);
  const [selectedPartId, setSelectedPartId] = useState(null);

  const [quoteCurrencyOptions, setQuoteCurrencyOptions] = useState([]);
  const [billingTypeOptions, setBillingTypeOptions] = useState([...billingTypesOptions]);
  const [billingFrequencyOptions, setBillingFrequencyOptions] = useState([]);

  const [razorpayPaymentId, setRazorpayPaymentId] = useState(null);
  const [razorpayOrderId, setRazorpayOrderId] = useState(null);
  const [razorpaySignatureId, setRazorpaySignatureId] = useState(null);

  const [partsOrderId, setPartsOrderId] = useState(null);
  const [orderRecord, setOrderRecord] = useState({
    ...partOrderObj,
    paymentTerms: paymentTermsOptions[0] || { label: "Immediate", value: "IMMEDIATE" },
    currency: quoteCurrencyOptions[0] || { label: "INR", value: "INR" },
    billingType: billingTypeOptions[0] || { label: "Pay Fixed Price", type: "PAY_FIXED_PRICE" },
    // billingType: billingTypeOptions[0] || { label: "Fixed Price", value: "FIXED_PRICE" },
    billingFrequency: billingFrequencyOptions[0] || { label: "One time", value: "ONE_TIME" },
    billingAddress: "",
    deliveryType: deliveryTypeOptions[0] || { label: "Standard", value: "STANDARD" },
    deliveryPriority: deliveryPriorityOptions[0] || { label: "Normal", value: "NORMAL" },
    shippingCondition: shippingCondition[0] || { label: "Ship to Me", value: "SHIP_TO_ME" },
    shippingPoint: shippingPointOptions[0] || { label: "Shipping Point 1", value: "SHIPPING_POINT_1" },
    leadTime: "20",
    shippingAddress: "1 Alan St, Rydalmere NSW 2116, Australia",
    pickUpInst: "",
    partialShipment: false,
    machineBreakdown: false,
    needInstallationHelp: false,
    serialNumber: "",
    model: "",
    orderAsFavorite: false,
    accountName: auth?.customerName || "",
    orderType: orderTypeOptions[0],
    orderedBy: "",
    emailAddress: "",
    phoneNumber: "",
    source: cartItems?.cartNumber,
    customerId: auth?.customerId,
    customerName: auth?.customerName,
  });

  const [orderedParts, setOrderedParts] = useState([]);

  const [razorpayOrder, setRazorpayOrder] = useState({ ...razorPayOrderObj });
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const [selectPaymentType, setSelectedPaymentType] = useState("");
  const [showPromoCodeInput, setShowPromoCodeInput] = useState(false);
  const [selectAddressType, setSelectAddressType] = useState("");
  const [labourCharge, setLabourCharge] = useState(0);

  const [paymentShoppingObj, setPaymentShoppingObj] = useState({
    subTotal: "",
    discount: "",
    tax: "",
    shipping: "",
    total: "",
  });

  const [updatecount, setUpdatecount] = useState(0);

  const [severity, setSeverity] = useState("");
  const [openSnack, setOpenSnack] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const handleSnackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const handleSnack = (snackSeverity, snackMessage) => {
    setSnackMessage(snackMessage);
    setSeverity(snackSeverity);
    setOpenSnack(true);
  };

  useEffect(() => {
    // Billing-Type
    // getQuoteCommonConfig("billing-type")
    //   .then((res) => {
    //     const options = [];
    //     res.length !== 0 &&
    //       res.map((d) => {
    //         if (d.key !== "EMPTY") {
    //           options.push({ value: d.key, label: d.value });
    //         }
    //       });
    //     setBillingTypeOptions(options);
    //   })
    // .catch((err) => {
    //   alert(err);
    // });

    // quote-Currency
    getQuoteCommonConfig("currency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d !== "EMPTY") {
              options.push({ value: d, label: d });
            }
          });
        // const options = res.map((d) => ({
        //   value: d,
        //   label: d,
        // }));
        setQuoteCurrencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });

    // Billing-Frequency
    getQuoteCommonConfig("billing-frequency")
      .then((res) => {
        const options = [];
        res.length !== 0 &&
          res.map((d) => {
            if (d.key !== "EMPTY") {
              options.push({ value: d.key, label: d.value });
            }
          });
        setBillingFrequencyOptions(options);
      })
      .catch((err) => {
        alert(err);
      });
  }, []);

  useEffect(() => {
    getCartItems();
  }, [updatecount]);

  // get the cart items list
  const getCartItems = async () => {
    await getCartParts(auth?.userId)
      .then((res) => {
        if (res) {
          setCartItems(res);
        } else {
          setCartItems(null);
        }
      })
      .catch((err) => setCartItems(null));
  };

  // decrease the step or steper
  const handleStepperDecrease = () => {
    let previousStep = activeStep - 1;
    if (cartOrderSteps[previousStep] === "Payment" && orderRecord?.paymentTerms?.value === "CREDIT") {
      previousStep -= 1;
    }
    setActiveStep(previousStep);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // increase the step or steper
  const handleStepperIncrease = () => {
    let nextStep = activeStep + 1;
    if (cartOrderSteps[nextStep] === "Payment" && orderRecord?.paymentTerms?.value === "CREDIT") {
      nextStep += 1;
    }
    setActiveStep(nextStep);
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Smooth scrolling
    });
  };

  // delete Part Item
  const removePartItem = async (item) => {
    const partType = cartItems && cartItems.parts.length > 0 ? "part" : "portfolio";
    const name = cartItems && cartItems.parts.length > 0 ? item?.partNumber : "portfolio";
    deleteConfirmationModal(
      partType,
      item?.partNumber,
      `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${item["partsId"]}`,
      handleSnack,
      getCartItems,
      cartItems,
      setCartItems,
      item?.partsId
    );
  };

  // delete portfolio Item
  const removePortfolioItem = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove ${item?.name}!`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const _portfolioCarts = portfolioCarts.filter((portfolio) => portfolio?.portfolioId !== item?.portfolioId);
        setPortfolioCarts(_portfolioCarts);
        handleSnack("success", `${item?.name} removed from cart successfully.`);
      } else {
        handleSnack("info", "Item removal canceled.");
        return false;
      }
    });
  };

  // delete all cart items
  const handleDeleteAllCartItems = async (item) => {
    if (cartItems && cartItems.parts.length > 0) {
      const rUrl =
        `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&&` +
        cartItems["parts"].map((item) => `ids=${item.partsId}`).join("&");

      deleteAllCartItemsModal("part", rUrl, handleSnack, getCartItems);
    } else {
      let textValue = "all Items";
      Swal.fire({
        title: "Are you sure?",
        text: `You want to remove ${textValue}!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          handleSnack("success", `${textValue} removed from cart successfully.`);
          setPortfolioCarts([]);
        } else {
          handleSnack("info", "Item removal canceled.");
          return false;
        }
      });
    }
  };

  // remove Portfolio || Part item form cart list
  const removeItem = async (portfolioId) => {
    if (cartItems && cartItems.parts.length !== 0) {
      // getCartItems();
      const _cartParts = cartItems["parts"].filter((obj) => obj.partsId !== portfolioId);
      setCartItems(_cartParts);
      handleSnack("success", "Cart item has been removed!");
    } else {
      await removeCart(portfolioId)
        .then((data) => {
          setPortfolioCarts(data.data.portfolio);
          handleSnack("success", "Cart item has been removed!");
        })
        .catch((err) => {
          handleSnack("error", "Something went wrong while removing the cart item!");
          console.log(err.message);
        });
    }
  };

  // ! shipping and Billing alternste functions

  // cart increase-decrease
  const handleButtonChange = (direction, row) => {
    let next;

    switch (direction) {
      case "up":
        next = row.quantity + (1 || 1);
        break;
      case "down":
        next = row.quantity - (1 || 1) >= 0 ? row.quantity - (1 || 1) : 0;
        break;
      default:
        next = row.quantity;
        break;
    }
    updateCartValue(next, row);
    if (next !== 0) {
    }
  };

  // update cart quantity
  const updateCartValue = async (value, itemrow) => {
    if (cartItems) {
      const partsCartRes = cartItems["parts"];
      let row = partsCartRes.find((obj) => obj?.partNumber === itemrow?.partNumber);

      const exists = !!row;
      if (exists) {
        row = { ...row, quantity: value };
        const rObj = {
          // partsCartId: cartItems?.cartId,
          cartId: cartItems?.cartId,
          partsQuantity: {
            [String(row["partsId"])]: value,
          },
        };

        if (value == 0) {
          let dltRUrl = `${PARTS_DELETE_PARTS_FROM_CART_DELETE}?parts_cart_id=${cartItems?.cartId}&ids=${row["partsId"]}`;
          callDeleteApi(null, dltRUrl, (response) => {
            if (response.status === API_SUCCESS) {
              handleSnack("info", `Part Number ${itemrow?.partNumber} removed from cart`);
              setUpdatecount(updatecount + 1);
            }
          });
        } else {
          callPostApi(null, PARTS_ADD_QUANTITY_TO_PARTS_CART_POST, rObj, (response) => {
            if (response.status === API_SUCCESS) {
              let _partsCartRes = partsCartRes.map((obj) => (obj?.id === row?.id ? row : obj));
              setCartItems({ ...cartItems, parts: [..._partsCartRes] });
              setUpdatecount(updatecount + 1);
            }
          });
        }
      }
    }
  };

  // save as favorite order or not
  const handleSaveAsFavoriteOrder = (e) => {
    const { checked } = e.target;
    setOrderRecord({ ...orderRecord, orderAsFavorite: checked });
    if (!checked) {
      handleSnack("info", "the order is removed from favorite Orders");
    } else {
      handleSnack("info", "the order is saved as favorite");
    }
  };

  // open part item details modal
  const handleViewPartItemDetailsModal = (row) => {
    setSelectedPartId(row.id);
    setOpenPartRow(row);
    setOpenPartItemDtlModl(true);
  };

  // close part item details modal
  const handleClosePartItemDetailsModal = () => {
    setSelectedPartId(null);
    setOpenPartItemDtlModl(false);
  };

  // add update parts order
  const handleAddUpdateOrder = async () => {
    if (cartItems && cartItems["parts"].length > 0) {
      const rUrl = PARTS_ORDER_MASTER_URL;
      const rObj = {
        ...orderRecord,
        paymentTerms: orderRecord?.paymentTerms?.value || "IMMEDIATE",
        currency: orderRecord?.currency?.value || "USD",
        billingType: orderRecord?.billingType?.value || "PAY_FIXED_PRICE",
        billingFrequency: orderRecord?.billingFrequency?.value || "ONE_TIME",
        deliveryType: orderRecord?.deliveryType?.value || "STANDARD",
        deliveryPriority: orderRecord?.deliveryPriority?.value || "NORMAL",
        shippingCondition: orderRecord?.shippingCondition?.value || "SHIP_TO_ME",
        shippingPoint: orderRecord?.shippingPoint?.value || "SHIPPING_POINT_1",
        orderType: orderRecord?.orderType?.value || "EMMERGENCY",
        partsPrice: (cartItems && cartItems["parts"].length !== 0 && cartItems?.cartPrice) || 0,
        servicePrice: 0,
        laborPrice: labourCharge,
        miscPrice: 0,
        environmentalPrice: 0,
        tax: (cartItems && cartItems["parts"].length !== 0 && (cartItems?.cartPrice * 10) / 100) || 0,
        costPrice: 0,
        deliveryPrice: 0,
        totalPrice:
          (cartItems &&
            cartItems["parts"].length !== 0 &&
            cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge) ||
          0,
        margin: 0,
        discount: (cartItems && cartItems["parts"].length !== 0 && (cartItems?.cartPrice * 2) / 100) || 0,
        netPrice: 0,
        adjustedPrice: 0,
        cartId: cartItems?.cartId,
        source: cartItems?.cartNumber,
        customerId: auth?.customerId,
        customerName: auth?.customerName,
      };
      if (partsOrderId) {
        callPutApi(null, `${rUrl}/${partsOrderId}`, { ...rObj, partsOrderId: partsOrderId }, (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setOrderRecord({
              ...orderRecord,
              partsOrderName: responseData.partsOrderName || "",
              partOrderNumber: responseData?.partOrderNumber || "",
            });
            setOrderedParts(responseData.partsQty);
            handleStepperIncrease();
          } else {
          }
        });
      } else {
        callPostApi(null, rUrl, rObj, (response) => {
          if (response.status === API_SUCCESS) {
            const responseData = response.data;
            setOrderRecord({
              ...orderRecord,
              partsOrderName: responseData.partsOrderName || "",
              partOrderNumber: responseData?.partOrderNumber || "",
            });
            setPartsOrderId(responseData.partsOrderId);
            setOrderedParts(responseData.partsQty);
            handleStepperIncrease();
          } else {
          }
        });
      }
    } else if (portfolioCarts.length > 0) {
      const randomNumber = Math.floor(Math.random() * 1000000)
        .toString()
        .padStart(6, "0");
      setPortfolioOrderNumber(`PO${randomNumber}`);

      handleStepperIncrease();
    } else {
      handleStepperIncrease();
    }
  };

  // ! Payment screen apply functions

  // change payment type is PayPal or Credit Card
  const handleChangePaymentType = (event) => {
    setSelectedPaymentType(event.target.value);
  };

  // show Promo Code apply input field
  const handleShowPromoCodeInput = () => {
    setShowPromoCodeInput(!showPromoCodeInput);
  };

  // change addres method or type
  const handleChangeAddressType = (e) => {
    setSelectAddressType(e.target.value);
  };

  // update status as PAID
  const handlePayNow = () => {
    handleCreateRazorPayOrder((res) => {
      if (res) {
        if (cartItems && cartItems["parts"].length) {
          const rUrl = PARTS_ORDER_MASTER_URL;
          const rObj = {
            ...orderRecord,
            paymentTerms: orderRecord?.paymentTerms?.value || "IMMEDIATE",
            currency: orderRecord?.currency?.value || "USD",
            billingType: orderRecord?.billingType?.value || "PAY_FIXED_PRICE",
            billingFrequency: orderRecord?.billingFrequency?.value || "ONE_TIME",
            deliveryType: orderRecord?.deliveryType?.value || "STANDARD",
            deliveryPriority: orderRecord?.deliveryPriority?.value || "NORMAL",
            shippingCondition: orderRecord?.shippingCondition?.value || "SHIP_TO_ME",
            shippingPoint: orderRecord?.shippingPoint?.value || "SHIPPING_POINT_1",
            orderType: orderRecord?.orderType?.value || "EMMERGENCY",

            partsPrice: (cartItems && cartItems["parts"].length !== 0 && cartItems?.cartPrice) || 0,
            servicePrice: 0,
            laborPrice: labourCharge,
            miscPrice: 0,
            environmentalPrice: 0,
            tax: (cartItems && cartItems["parts"].length !== 0 && (cartItems?.cartPrice * 10) / 100) || 0,
            costPrice: 0,
            deliveryPrice: 0,
            totalPrice:
              (cartItems &&
                cartItems["parts"].length !== 0 &&
                cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge) ||
              0,
            margin: 0,
            discount: (cartItems && cartItems["parts"].length !== 0 && (cartItems?.cartPrice * 2) / 100) || 0,
            netPrice: 0,
            adjustedPrice: 0,
            cartId: cartItems?.cartId,
            source: cartItems?.cartNumber,
            customerId: auth?.customerId,
            customerName: auth?.customerName,
            // status: "PAID",
          };

          if (partsOrderId) {
            callPutApi(null, `${rUrl}/${partsOrderId}`, { ...rObj, partsOrderId: partsOrderId }, (response) => {
              if (response.status === API_SUCCESS) {
                const responseData = response.data;
                setOrderRecord({
                  ...orderRecord,
                  partsOrderName: responseData.partsOrderName || "",

                  // status: "PAID",
                });
                handleStepperIncrease();
              }
            });
          }
        } else if (portfolioCarts.length > 0) {
          if (!portfolioOrderNumber) {
            const randomNumber = Math.floor(Math.random() * 1000000)
              .toString()
              .padStart(6, "0");
            setPortfolioOrderNumber(`PO${randomNumber}`);
          }
          handleStepperIncrease();
        }
      }
    });
  };

  // close the payment success model
  const handleClosePaymentSuccessModal = () => {
    setShowPaymentSuccessModal(false);
  };

  // create the rozerpay order
  const handleCreateRazorPayOrder = async () => {
    const rObj = {
      ...razorpayOrder,
      amount: Number(
        (cartItems &&
          cartItems["parts"].length !== 0 &&
          (
            cartItems["parts"].reduce((sum, cartItem) => {
              return sum + cartItem?.listPrice * cartItem?.quantity;
            }, 0) +
            (cartItems["parts"].reduce((sum, cartItem) => {
              return sum + cartItem?.listPrice * cartItem?.quantity;
            }, 0) *
              10) /
              100 -
            (cartItems["parts"].reduce((sum, cartItem) => {
              return sum + cartItem?.listPrice * cartItem?.quantity;
            }, 0) *
              2) /
              100 +
            labourCharge
          ).toFixed(2)) ||
          0
      ),
    };
    setShowPaymentSuccessModal(true);
    await createRozerPayOrder(rObj)
      .then((responseData) => {
        if (responseData) {
          setRazorpayOrder({ ...razorpayOrder, orderId: responseData?.orderId });
          setShowPaymentSuccessModal(true);
          return true;
          // const options = {
          //   key: "rzp_test_iAE8FMIzXzpxTY",
          //   amount: responseData?.amount * 100,
          //   currency: "INR",
          //   name: "Auxentious Technologies Pvt. Ltd.",
          //   description: "Test Transaction",
          //   // image: "https://your-logo-url.com/logo.png",
          //   image:
          //     "https://media.licdn.com/dms/image/v2/D4D0BAQEZeQ0lXb7UKw/company-logo_200_200/company-logo_200_200/0/1693828574688?e=2147483647&v=beta&t=T2xzXGM7eaOOVGVnpLN4YU_s4KiSYkn3y2LJvF7UUdE",
          //   order_id: responseData.orderId,
          //   handler: function (response) {
          //     // setRazorpayPaymentId(response.razorpay_payment_id);
          //     // setRazorpayOrderId(response.razorpay_order_id);
          //     // setRazorpaySignatureId(response.razorpay_signature);
          //   },
          //   prefill: {
          //     name: "Rohan Verma Doe",
          //     email: "jmigisif774@stikezz.com",
          //     contact: "9999999999",
          //   },
          //   notes: {
          //     address: "Corporate Office",
          //   },
          //   method: {
          //     card: true, // This ensures the card payment method is pre-selected
          //     netbanking: false, // Disable netbanking
          //     upi: false, // Disable UPI
          //     wallet: false, // Disable wallet payments
          //   },
          //   theme: {
          //     color: "#3399cc",
          //   },
          // };

          // const rzp = new window.Razorpay(options);
          // rzp.open();

          // rzp.on("payment.failed", function (response) {
          //   console.error(`Payment Failed: ${response.error.description}`);
          // });

          // rzp.on("payment.success", function (response) {
          //   console.log(`Payment Success! Payment ID: ${response.razorpay_payment_id}`);
          // });

          // rzp.on("payment.processing", function () {
          //   console.log("Payment is processing...");
          // });
        } else {
          handleSnack("error", "Something went wrong");
          return false;
        }
      })
      .catch((error) => {
        handleSnack("error", "Something went wrong");
        return false;
      });
  };

  // ! Order Summary functions

  const generateRandomString = (length) => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    const charactersLength = characters.length;

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength);
      result += characters.charAt(randomIndex);
    }

    return result;
  };

  // get the shopping cart number === for portfolio generate a new number
  const getShoppingCartNumber = () => {
    if (cartItems) {
      return cartItems?.cartNumber;
      // return orderRecord?.partOrderNumber;
    } else if (portfolioCarts.length !== 0) {
      if (!portfolioCartNumber) {
        setPortfolioCartNumber(generateRandomString(12));
        return generateRandomString(12);
      } else {
        return portfolioCartNumber;
      }
    }
  };

  // view cart items card
  const viewCartItemsList = (showViewBtn = true) => {
    return (
      <>
        {portfolioCarts.length === 0 && cartItems && cartItems["parts"].length !== 0
          ? cartItems["parts"].map((item) => {
              if (item.quantity > 0) {
                return (
                  <Grid item xs={12} md={6} sx={{ p: 4 }} key={item.partsId}>
                    <PartCartItem
                      key={item.partsId}
                      item={item}
                      setCartItems={setCartItems}
                      removeItem={removePartItem}
                      handleSnack={handleSnack}
                      cartItems={cartItems}
                      getCartItems={getCartItems}
                      showViewBtn={showViewBtn}
                      handleViewPartItemDetailsModal={handleViewPartItemDetailsModal}
                    />
                  </Grid>
                );
              }
            })
          : portfolioCarts.map((item) => (
              <Grid item xs={12} md={6} sx={{ p: 4 }}>
                <PortfolioCartItems
                  key={item.portfolioId}
                  item={item}
                  cartItems={portfolioCarts}
                  setCartItems={setPortfolioCarts}
                  removeItem={removePortfolioItem}
                  showViewBtn={showViewBtn}
                  handleSnack={handleSnack}
                />
                {/* <PortfolioCartItem
                  key={item.portfolioId}
                  item={item}
                  cartItems={portfolioCarts}
                  setCartItems={setPortfolioCarts}
                  removeItem={removeItem}
                  handleSnack={handleSnack}
                  showViewBtn={showViewBtn}
                /> */}
              </Grid>
            ))}
      </>
    );
  };

  // shopping Cart items list
  const shoppingCartItemsList = () => {
    return (
      <>
        {portfolioCarts.length !== 0 || (cartItems && cartItems["parts"].length !== 0) ? (
          <div className="card p-4">
            <div className="col-lg-9 col-md-9">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5 className="">Shopping Cart {`(${getShoppingCartNumber()})`}</h5>
                <button className="btn border-primary text-primary" onClick={handleDeleteAllCartItems}>
                  Delete All
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-9 col-sm-9">
                <Divider />
                <div className="card" style={{ overflow: "hidden" }}>
                  <div className="shopping-cart-items-card">
                    <Grid container>{viewCartItemsList(false)}</Grid>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                {/* {getCartPriceCard()} */}
                <CartPriceCard
                  cartItems={cartItems}
                  portfolioCarts={portfolioCarts}
                  labourCharge={labourCharge}
                  currencyFormatter={currencyFormatter}
                  orderRecord={orderRecord}
                />
                {((cartItems && cartItems["parts"].length !== 0) || portfolioCarts.length !== 0) && (
                  <>
                    <div className="row ">
                      <div className="col-md-12 col-sm-12 my-3">
                        <button className="btn bg-primary text-white cursor w-100" onClick={handleStepperIncrease}>
                          SHIPPING & BILLING
                        </button>
                      </div>
                      <div className="col-md-12 col-sm-12">
                        <NavLink to={"/"} className="btn border-primary text-primary cursor w-100">
                          CANCEL
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="card border p-4">
            <div className="d-flex justify-content-between align-items-center">
              <h4>Your cart is empty, please add some portfolios or parts to your cart</h4>
              <NavLink to={"/"} className="btn bg-primary text-white cursor">
                <KeyboardBackspaceIcon /> Back
              </NavLink>
            </div>
          </div>
        )}
      </>
    );
  };

  // shipping and Billing Cart List
  const shippingBillingCartItemsList = () => {
    return (
      <>
        <div className="padding-custom">
          <div className="mt-4">
            <div className="row">
              <div className="col-md-9 col-sm-9">
                <div className="card" style={{ overflow: "hidden" }}>
                  <h5 className="mb-0 bg-primary px-4 py-2 text-white">Shopping Cart {`(${getShoppingCartNumber()})`}</h5>
                  <div className="shopping-cart-items-card">
                    <Grid container>{viewCartItemsList(true)}</Grid>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-3">
                <CartPriceCard
                  cartItems={cartItems}
                  portfolioCarts={portfolioCarts}
                  labourCharge={labourCharge}
                  currencyFormatter={currencyFormatter}
                  orderRecord={orderRecord}
                />
                <OrderShippingDtlsCard
                  orderRecord={orderRecord}
                  setOpenShippingDtlsModal={setOpenShippingDtlsModal}
                  showIcon={true}
                  className="px-2 py-2"
                />
                <OrderBillingDtlsCard
                  orderRecord={orderRecord}
                  setOpenBillingDtlsModal={setOpenBillingDtlsModal}
                  showIcon={true}
                  className="px-2 py-2"
                />
                <div className="px-2">
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox checked={orderRecord?.orderAsFavorite} />}
                      sx={{ fontSize: 14 }}
                      onChange={handleSaveAsFavoriteOrder}
                      label={"Save as Favorite"}
                    />
                  </FormGroup>
                </div>
                <div className="row mt-2 px-2">
                  <div className="col-md-6 col-sm-6">
                    <button className="btn border-primary bg-white w-100" onClick={handleStepperDecrease}>
                      CANCEL
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-6">
                    {/* <button className="btn btn-primary cursor" onClick={handleOrderPayment}> */}
                    <button
                      className={`btn bg-primary text-white cursor ${orderRecord?.paymentTerms?.value === "CREDIT" ? "px-2" : "w-100"}`}
                      onClick={handleAddUpdateOrder}
                    >
                      {orderRecord?.paymentTerms?.value === "CREDIT" ? "GENERATE QUOTE" : "CHECKOUT"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // Payment
  const shoppingPayment = () => {
    return (
      <>
        <Grid container sx={{ width: "100%", backgroundColor: "#f3eafe", borderRadius: 5, marginBlock: 4, paddingInline: 3, paddingBlock: 3 }}>
          <Grid item xs={12} md={7} sx={{ pl: 2, pr: 4.5, py: 2 }}>
            <div className="card border px-3 py-3">
              <h4 className="px-1"> Payment Method</h4>
              <div className="card border-primary py-2">
                <div className="d-flex align-items-center justify-content-between px-3">
                  <FormControlLabel
                    value="creditOrDebitCard"
                    control={<Radio checked={selectPaymentType === "creditOrDebitCard"} onChange={handleChangePaymentType} />}
                    label=" Credit or Debit Card"
                  />
                  <div className="d-flex justify-content-end">
                    <img
                      src="https://w7.pngwing.com/pngs/594/666/png-transparent-visa-logo-credit-card-debit-card-payment-card-bank-visa-blue-text-rectangle-thumbnail.png"
                      width={50}
                    />
                    <span>
                      <img src="https://i.pinimg.com/736x/56/fd/48/56fd486a48ff235156b8773c238f8da9.jpg" width={50} />
                    </span>
                  </div>
                </div>
                <Divider />
                {selectPaymentType === "creditOrDebitCard" && (
                  <div className="card-body payment-card-body px-3 mt-0 bg-light">
                    <span className="font-weight-normal card-text">Card Number</span>
                    <div className="card-payment-input mb-3">
                      <i className="fa fa-credit-card"></i>
                      <input type="text" className="form-control" placeholder="0000 0000 0000 0000" />
                    </div>
                    <span className="font-weight-normal mt-3 card-text ">Card Holder Name</span>
                    <div className="mb-3">
                      <input type="text" className="form-control pl-2" placeholder="Daniel Humphrey" />
                    </div>
                    <div className="row mb-3">
                      <div className="col-md-6">
                        <span className="font-weight-normal card-text">Expiry Date</span>
                        <div className="align-items-center row mb-2">
                          <div className="col-md-5">
                            <div className="">
                              <input type="text" className="form-control" placeholder="MM" />
                            </div>
                          </div>
                          <div className="col-md-1 text-center">/</div>
                          <div className="col-md-5 ">
                            <div className="">
                              <input type="text" className="form-control" placeholder="YY" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 ">
                        <span className="font-weight-normal card-text">CVC/CVV</span>
                        <div className="card-payment-input">
                          <i className="fa fa-lock"></i>
                          <input type="text" className="form-control " placeholder="000" />
                        </div>
                      </div>
                    </div>
                    <span className="text-muted certificate-text">
                      <i className="fa fa-lock"></i> Your transaction is secured with ssl certificate
                    </span>
                  </div>
                )}
                <div className="d-flex align-items-center justify-content-between px-3">
                  <FormControlLabel
                    value="paypal"
                    control={<Radio checked={selectPaymentType === "paypal"} onChange={handleChangePaymentType} />}
                    label="PayPal"
                  />
                  <div className="d-flex justify-content-end">
                    <img src="https://cdn.pixabay.com/photo/2015/05/26/09/37/paypal-784404_1280.png" width={100} alt="PayPal" />
                  </div>
                </div>
                {selectPaymentType === "paypal" && (
                  <>
                    {/* <Divider /> */}
                    <div className="form-group my-2 px-2 bg-light">
                      {/* <label htmlFor="emailOrPhone" className="form-label mb-3 ml-1">
                      Enter Your Email Or Phone Number To Get Started
                      </label> */}
                      <input className="form-control border-radius-10" placeholder="Enter Your Email or Phone Number ..." />
                    </div>
                  </>
                )}
              </div>

              <h4 className="mb-0 px-1">Billing</h4>
              <p className="px-1">
                <small>Select the address that matches your card or payment method</small>
              </p>
              <div className=" card border-primary my-2 px-3 py-2">
                <FormControlLabel
                  value="shippingaddress"
                  control={<Radio checked={selectAddressType === "shippingaddress"} onChange={handleChangeAddressType} />}
                  label=" Same as Shipping Address"
                />
                <Divider />
                <FormControlLabel
                  value="differentaddress"
                  control={<Radio checked={selectAddressType === "differentaddress"} onChange={handleChangeAddressType} />}
                  label=" Use A Different Billing Address"
                />
                {selectAddressType === "differentaddress" && (
                  <div className="form-group bg-light">
                    <label>Enter Your Billing Address</label>
                    <textarea className="form-control border-radius-10" placeholder="Enter Your Billing Address ..." rows="4"></textarea>
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={5} sx={{ mt: 2 }}>
            <div className="card border px-3 py-3">
              <h4 className="px-1"> Summary</h4>
              <div className="card border-primary px-3 py-2">
                <div className="d-flex justify-content-between align-items-center py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Currency</p>
                  <div className="mb-0">
                    <Select
                      className="text-primary"
                      placeholder="Select..."
                      options={quoteCurrencyOptions}
                      value={orderRecord?.currency}
                      onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                    />
                  </div>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Sub Total</p>
                  <h6 className="mb-0">
                    <b>
                      {cartItems && cartItems["parts"].length !== 0
                        ? currencyFormatter.format(cartItems?.cartPrice)
                        : currencyFormatter.format(
                            portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0)
                          )}
                    </b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Discount</p>
                  <h6 className="mb-0">
                    <b>
                      {cartItems && cartItems["parts"].length !== 0
                        ? currencyFormatter.format((cartItems?.cartPrice * 2) / 100)
                        : currencyFormatter.format(
                            (portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) *
                              2) /
                              100
                          )}
                    </b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Tax</p>
                  <h6 className="mb-0">
                    <b>
                      {cartItems && cartItems["parts"].length !== 0
                        ? currencyFormatter.format((cartItems?.cartPrice * 10) / 100)
                        : currencyFormatter.format(
                            (portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) *
                              10) /
                              100
                          )}
                    </b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Shipping</p>
                  <h6 className="mb-0">
                    <b>FREE</b>
                  </h6>
                </div>
                <div className="hr my-0"></div>
                <div className="d-flex justify-content-between py-2">
                  <p className="text-light-dark font-size-13 font-weight-500 mb-0">Total</p>
                  <h6 className="mb-0">
                    <b>
                      {cartItems &&
                        cartItems["parts"].length !== 0 &&
                        currencyFormatter.format(
                          cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge
                        )}
                      {portfolioCarts.length !== 0 &&
                        currencyFormatter.format(
                          portfolioCarts.reduce((sum, cartItem) => {
                            return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                          }, 0) +
                            (portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) *
                              10) /
                              100 -
                            (portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) *
                              2) /
                              100
                        )}
                    </b>
                  </h6>
                </div>
                {/* <div className="hr my-0"></div> */}
                {/* <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label className="text-light-dark font-size-13 font-weight-500">Sub Total</label>
                    <div className="form-group w-100">
                      <div className=" d-flex form-control-date ">
                        <Select
                          className="select-input text-primary w-25"
                          placeholder="Select..."
                          options={quoteCurrencyOptions}
                          value={orderRecord?.currency}
                          onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                        />
                        <input
                          type="text"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="From"
                          value={currencyFormatter.format(cartItems?.cartPrice)}
                          disabled
                          // onChange={(e) => {
                          //   const inputValue = parseFloat(e.target.value);
                          //   if (isNaN(inputValue)) {
                          //     e.target.value = 0;
                          //   } else {
                          //     e.target.value = Math.max(inputValue, 0);
                          //   }
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label className="text-light-dark font-size-13 font-weight-500">Discount</label>
                    <div className="form-group w-100">
                      <div className=" d-flex form-control-date ">
                        <Select
                          className="select-input text-primary w-25"
                          placeholder="Select..."
                          options={quoteCurrencyOptions}
                          value={orderRecord?.currency}
                          onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                        />
                        <input
                          type="text"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="From"
                          value={currencyFormatter.format(cartItems?.cartPrice)}
                          disabled
                          // onChange={(e) => {
                          //   const inputValue = parseFloat(e.target.value);
                          //   if (isNaN(inputValue)) {
                          //     e.target.value = 0;
                          //   } else {
                          //     e.target.value = Math.max(inputValue, 0);
                          //   }
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label className="text-light-dark font-size-13 font-weight-500">Tax</label>
                    <div className="form-group w-100">
                      <div className=" d-flex form-control-date ">
                        <Select
                          className="select-input text-primary w-25"
                          placeholder="Select..."
                          options={quoteCurrencyOptions}
                          value={orderRecord?.currency}
                          onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                        />
                        <input
                          type="text"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="From"
                          value={currencyFormatter.format((cartItems?.cartPrice * 10) / 100)}
                          disabled
                          // onChange={(e) => {
                          //   const inputValue = parseFloat(e.target.value);
                          //   if (isNaN(inputValue)) {
                          //     e.target.value = 0;
                          //   } else {
                          //     e.target.value = Math.max(inputValue, 0);
                          //   }
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label className="text-light-dark font-size-13 font-weight-500">Shipping</label>
                    <div className="form-group w-100">
                      <div className=" d-flex form-control-date ">
                        <Select
                          className="select-input text-primary w-25"
                          placeholder="Select..."
                          options={quoteCurrencyOptions}
                          value={orderRecord?.currency}
                          onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                        />
                        <input
                          type="text"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="Free"
                          // value={currencyFormatter.format(cartItems?.cartPrice)}
                          disabled
                          // onChange={(e) => {
                          //   const inputValue = parseFloat(e.target.value);
                          //   if (isNaN(inputValue)) {
                          //     e.target.value = 0;
                          //   } else {
                          //     e.target.value = Math.max(inputValue, 0);
                          //   }
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <label className="text-light-dark font-size-13 font-weight-500">Total</label>
                    <div className="form-group w-100">
                      <div className=" d-flex form-control-date ">
                        <Select
                          className="select-input text-primary w-25"
                          placeholder="Select..."
                          options={quoteCurrencyOptions}
                          value={orderRecord?.currency}
                          onChange={(e) => setOrderRecord({ ...orderRecord, currency: e })}
                        />
                        <input
                          type="text"
                          className="form-control rounded-top-left-0 rounded-bottom-left-0 text-primary"
                          placeholder="From"
                          value={currencyFormatter.format(
                            cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge
                          )}
                          disabled
                          // onChange={(e) => {
                          //   const inputValue = parseFloat(e.target.value);
                          //   if (isNaN(inputValue)) {
                          //     e.target.value = 0;
                          //   } else {
                          //     e.target.value = Math.max(inputValue, 0);
                          //   }
                          // }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="d-flex align-items-baseline my-2 font-weight-500">
                  <input type="checkbox" id="promoCode" onChange={handleShowPromoCodeInput} />

                  <div className="text-primary d-inline-flex align-items-center ml-3" htmlFor="promoCode">
                    <strong>
                      <u>Have A Promo Code ?</u>
                    </strong>
                  </div>
                </div>
                {showPromoCodeInput && (
                  <div className="mt-2">
                    <input type="text" className="form-control" placeholder="Enter Your Promo Code" />
                  </div>
                )}

                <div className="d-flex justify-content-between align-items-center my-3 px-3">
                  <button type="button" className="btn border-primary w-100 mr-1" onClick={handleStepperDecrease}>
                    CANCEL
                  </button>
                  <button type="button" className="btn bg-primary text-white w-100 ml-1" onClick={handlePayNow}>
                    PAY NOW
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    );
  };

  // part order columns
  const partOrderColumns = [
    {
      field: "partsId",
      headerName: "ID",
      flex: 1,
      renderCell: (params) => params.id,
    },
    { field: "partNumber", headerName: "Part Number", flex: 1 },
    { field: "partDescription", headerName: "Part Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "listPrice", headerName: "Price", flex: 1, renderCell: ({ row }) => currencyFormatter.format(row.listPrice) },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row.totalPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">{currencyFormatter.format(row.totalPrice + (row.totalPrice * 10) / 100)}</div>
      ),
    },
  ];

  // part order columns
  const portfolioOrderColumns = [
    {
      field: "portfolioId",
      headerName: "Portfolio ID",
      flex: 1,
      renderCell: (params) => params.portfolioId,
    },
    { field: "name", headerName: "Portfolio Name", flex: 1 },
    { field: "description", headerName: "Portfolio Description", flex: 1 },
    { field: "quantity", headerName: "Quantity", flex: 1 },
    { field: "calculated", headerName: "Price", flex: 1, renderCell: ({ row }) => currencyFormatter.format(row?.portfolioPrice?.calculatedPrice) },
    {
      field: "tax",
      headerName: "Tax",
      flex: 1,
      renderCell: ({ row }) => currencyFormatter.format((row?.portfolioPrice?.calculatedPrice * 10) / 100),
    },
    {
      field: "totalPrice",
      headerName: "Total Price",
      flex: 1,
      renderCell: ({ row }) => (
        <div className="font-weight-500 font-size-12">
          {currencyFormatter.format(row?.portfolioPrice?.calculatedPrice + (row?.portfolioPrice?.calculatedPrice * 10) / 100)}
        </div>
      ),
    },
  ];

  // order summary
  const orderSummary = () => {
    return (
      <>
        <div className="card border px-4 py-3">
          <div className="d-flex justify-content-between my-2 mt-2 ">
            <div>
              {/* <h3 className="text-light">#{orderRecord?.partsOrderName || portfolioOrderNumber || "NA"}</h3> */}
              <h3 className="text-light">#{orderRecord?.partOrderNumber || portfolioOrderNumber || "NA"}</h3>
              <h5 className="text-muted">
                Order History / Order Details / {getShoppingCartNumber()} - <Moment format="DD-MM-YYYY hh:mm A">{orderRecord?.createdAt}</Moment>
              </h5>
            </div>
            <div>
              <NavLink to={"/transactions"} className="btn btn-info mr-2 cursor">
                <TrackChangesIcon /> Track Order
              </NavLink>
              {/* <button className="btn btn-info mr-2 "></button> */}
              <button className="btn btn-primary mr-2 ">
                <DriveFileRenameOutlineIcon /> Print
              </button>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <h4 className="mb-0">Progress</h4>
            <h5 className="text-muted font-size-13 my-1">Current Order Status</h5>
            <div className="d-flex">
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AllInboxIcon />
                </div>
                <h5 className="mt-2 mb-3">Order Confirming</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <PaymentIcon />
                </div>
                <h5 className="mt-2 mb-3">Payment Pending</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AccountTreeIcon />
                </div>
                <h5 className="mt-2 mb-3">Processing</h5>
                <ProgressBar progress={50} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <LocalShippingIcon />
                </div>
                <h5 className="mt-2 mb-3">Shipping</h5>
                <ProgressBar progress={0} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <CheckCircleRoundedIcon />
                </div>
                <h5 className="mt-2 mb-3">Delivered</h5>
                <ProgressBar progress={0} />
              </div>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Customer</h4>
                <h5 className="text-muted font-size-13 my-1">Information Details</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <OrderInfoCard orderRecord={orderRecord} setOpenOrderInfoModal={setOpenOrderInfoModal} showIcon={true} className="px-2 py-2" />
              </div>
              <div className="col-md-4 col-sm-4">
                <OrderShippingDtlsCard
                  orderRecord={orderRecord}
                  setOpenShippingDtlsModal={setOpenShippingDtlsModal}
                  showIcon={false}
                  className="px-2 py-2"
                  style={{ height: "17.4rem" }}
                />
              </div>
              <div className="col-md-4 col-sm-4">
                <OrderBillingDtlsCard
                  orderRecord={orderRecord}
                  setOpenBillingDtlsModal={setOpenBillingDtlsModal}
                  showIcon={false}
                  className="px-2 py-2"
                  style={{ height: "17.4rem" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-9 col-sm-9">
              <div className="card border p-2">
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    <h4 className="mb-1">Product</h4>
                    <h5 className="text-muted font-size-13 my-1">Your Shipment</h5>
                  </div>
                  <div>
                    <Tooltip title="Download CSV">
                      <DescriptionIcon className="font-size-32" />
                    </Tooltip>
                  </div>
                </div>
                <Divider className="mb-2" />
                <div className="order-summary-items">
                  <DataGrid
                    rows={cartItems && cartItems["parts"].length > 0 ? cartItems["parts"] : portfolioCarts}
                    columns={cartItems && cartItems["parts"].length > 0 ? partOrderColumns : portfolioOrderColumns}
                    disableSelectionOnClick
                    getRowId={(row) => (cartItems && cartItems["parts"].length > 0 ? row.partsId : row?.portfolioId)}
                    sx={GRID_STYLE}
                    hideFooter={true}
                    autoHeight
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="card border py-2 px-3 mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="mb-0">Payment</h4>
                    <h5 className="text-muted font-size-13 my-1">Final Payment Amount</h5>
                  </div>
                  <div>
                    <Tooltip title="Download Invoice">
                      <SimCardDownloadIcon className="font-size-32" />
                    </Tooltip>
                  </div>
                </div>
                <div className="hr my-0"></div>
                <div className="py-2">
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Sub Total</p>
                    <h6 className="mb-0">
                      <b>
                        {cartItems && cartItems["parts"].length !== 0 && currencyFormatter.format(cartItems?.cartPrice)}
                        {portfolioCarts.length !== 0 &&
                          currencyFormatter.format(
                            portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0)
                          )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Tax (10%)</p>
                    <h6 className="mb-0">
                      <b>
                        {cartItems && cartItems["parts"].length !== 0
                          ? currencyFormatter.format((cartItems?.cartPrice * 10) / 100)
                          : currencyFormatter.format(
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                10) /
                                100
                            )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="d-flex align-items-center mb-0">
                      <p className="mb-0">Discount</p>
                      <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                    </div>
                    <h6 className="mb-0">
                      <b>
                        -{" "}
                        {cartItems && cartItems["parts"].length !== 0
                          ? currencyFormatter.format((cartItems?.cartPrice * 2) / 100)
                          : currencyFormatter.format(
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                2) /
                                100
                            )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Shipping Cost</p>
                    <h6 className="mb-0">
                      <b>
                        {currencyFormatter.format(0)}
                        {/* {portfolioCarts.length !== 0
                          ? currencyFormatter.format(
                              portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.labourPrice * cartItem?.quantity;
                              }, 0)
                            )
                          : currencyFormatter.format(labourCharge)} */}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <h6 className="mb-0">
                      <b>Total</b>
                    </h6>
                    <h6 className="mb-0">
                      <b>
                        {cartItems &&
                          cartItems["parts"].length !== 0 &&
                          currencyFormatter.format(
                            cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge
                          )}
                        {portfolioCarts.length !== 0 &&
                          currencyFormatter.format(
                            portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) +
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                10) /
                                100 -
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                2) /
                                100
                          )}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row my-4 px-2">
                <div className="col-md-6 col-sm 6">
                  <button className="btn border-primary text-primary w-100 mr-2" onClick={handleStepperDecrease}>
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6 col-sm 6">
                  <button className="btn bg-primary text-white w-100 ml-2" onClick={handleStepperIncrease}>
                    ORDER NOW
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  // order confirmation
  const orderConfiramtion = () => {
    return (
      <>
        <div className="card border px-4 py-3">
          <div className="d-flex justify-content-between my-2 mt-2 ">
            <div>
              {/* <h3 className="text-light">#{orderRecord?.partsOrderName || portfolioOrderNumber || "NA"}</h3> */}
              <h3 className="text-light">#{orderRecord?.partOrderNumber || portfolioOrderNumber || "NA"}</h3>
              <h5 className="text-muted">
                Order History / Order Details / {getShoppingCartNumber()} - <Moment format="DD-MM-YYYY hh:mm A">{orderRecord?.createdAt}</Moment>
              </h5>
            </div>
            <div>
              <NavLink to={"/transactions"} className="btn btn-info mr-2 cursor">
                <TrackChangesIcon /> Track Order
              </NavLink>
              <button className="btn btn-primary mr-2 ">
                <DriveFileRenameOutlineIcon /> Print
              </button>
            </div>
          </div>
          <div className="card border px-3 py-2">
            <h4 className="mb-0">Progress</h4>
            <h5 className="text-muted font-size-13 my-1">Current Order Status</h5>
            <div className="d-flex">
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AllInboxIcon />
                </div>
                <h5 className="mt-2 mb-3">Order Confirming</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <PaymentIcon />
                </div>
                <h5 className="mt-2 mb-3">Payment Pending</h5>
                <ProgressBar progress={100} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <AccountTreeIcon />
                </div>
                <h5 className="mt-2 mb-3">Processing</h5>
                <ProgressBar progress={50} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <LocalShippingIcon />
                </div>
                <h5 className="mt-2 mb-3">Shipping</h5>
                <ProgressBar progress={0} />
              </div>
              <div className="card border p-3 m-2" style={{ width: "20%" }}>
                <div>
                  <CheckCircleRoundedIcon />
                </div>
                <h5 className="mt-2 mb-3">Delivered</h5>
                <ProgressBar progress={0} />
              </div>
            </div>
          </div>

          <div className="card border px-3 py-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="mb-0">Customer</h4>
                <h5 className="text-muted font-size-13 my-1">Information Details</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 col-sm-4">
                <OrderInfoCard orderRecord={orderRecord} setOpenOrderInfoModal={setOpenOrderInfoModal} showIcon={false} className="px-2 py-2" />
              </div>
              <div className="col-md-4 col-sm-4">
                <OrderShippingDtlsCard
                  orderRecord={orderRecord}
                  setOpenShippingDtlsModal={setOpenShippingDtlsModal}
                  showIcon={false}
                  className="px-2 py-2"
                  style={{ height: "17.4rem" }}
                />
              </div>
              <div className="col-md-4 col-sm-4">
                <OrderBillingDtlsCard
                  orderRecord={orderRecord}
                  setOpenBillingDtlsModal={setOpenBillingDtlsModal}
                  showIcon={false}
                  className="px-2 py-2"
                  style={{ height: "17.4rem" }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-9 col-sm-9">
              <div className="card border p-2">
                <div className="d-flex justify-content-between align-items-baseline">
                  <div>
                    <h4 className="mb-1">Product</h4>
                    <h5 className="text-muted font-size-13 my-1">Your Shipment</h5>
                  </div>
                  <div>
                    <Tooltip title="Download CSV">
                      <DescriptionIcon className="font-size-32" />
                    </Tooltip>
                  </div>
                </div>
                <div className="order-summary-items">
                  <DataGrid
                    rows={cartItems && cartItems["parts"].length > 0 ? cartItems["parts"] : portfolioCarts}
                    columns={cartItems && cartItems["parts"].length > 0 ? partOrderColumns : portfolioOrderColumns}
                    disableSelectionOnClick
                    getRowId={(row) => (cartItems && cartItems["parts"].length > 0 ? row.partsId : row?.portfolioId)}
                    sx={GRID_STYLE}
                    hideFooter={true}
                    autoHeight
                    pagination={false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              <div className="card border py-2 px-3 mb-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <h4 className="mb-0">Payment</h4>
                    <h5 className="text-muted font-size-13 my-1">Final Payment Amount</h5>
                  </div>
                  <div className="font-size-16">
                    <Tooltip title="Download Invoice">
                      <SimCardDownloadIcon className="font-size-32" />
                    </Tooltip>
                  </div>
                </div>
                <div className="py-2">
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Sub Total</p>
                    <h6 className="mb-0">
                      <b>
                        {cartItems && cartItems["parts"].length !== 0 && currencyFormatter.format(cartItems?.cartPrice)}
                        {portfolioCarts.length !== 0 &&
                          currencyFormatter.format(
                            portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0)
                          )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Tax (10%)</p>
                    <h6 className="mb-0">
                      <b>
                        {cartItems && cartItems["parts"].length !== 0
                          ? currencyFormatter.format((cartItems?.cartPrice * 10) / 100)
                          : currencyFormatter.format(
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                10) /
                                100
                            )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="d-flex align-items-center mb-0">
                      <p className="mb-0">Discount</p>
                      <p className="border border-radius-10 px-2 py-1 ml-1 mb-0">2% | REP</p>
                    </div>
                    <h6 className="mb-0">
                      <b>
                        -{" "}
                        {cartItems && cartItems["parts"].length !== 0
                          ? currencyFormatter.format((cartItems?.cartPrice * 2) / 100)
                          : currencyFormatter.format(
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                2) /
                                100
                            )}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <p className="mb-0">Shipping Cost</p>
                    <h6 className="mb-0">
                      <b>
                        {currencyFormatter.format(0)}
                        {/* {portfolioCarts.length !== 0
                          ? currencyFormatter.format(
                              portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem.portfolioPrice.labourPrice * cartItem?.quantity;
                              }, 0)
                            )
                          : currencyFormatter.format(labourCharge)} */}
                      </b>
                    </h6>
                  </div>
                  <div className="hr my-0"></div>
                  <div className="d-flex justify-content-between py-2">
                    <h6 className="mb-0">
                      <b>Total</b>
                    </h6>
                    <h6 className="mb-0">
                      <b>
                        {cartItems &&
                          cartItems["parts"].length !== 0 &&
                          currencyFormatter.format(
                            cartItems?.cartPrice + (cartItems?.cartPrice * 10) / 100 - (cartItems?.cartPrice * 2) / 100 + labourCharge
                          )}
                        {portfolioCarts.length !== 0 &&
                          currencyFormatter.format(
                            portfolioCarts.reduce((sum, cartItem) => {
                              return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                            }, 0) +
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                10) /
                                100 -
                              (portfolioCarts.reduce((sum, cartItem) => {
                                return sum + cartItem?.portfolioPrice?.calculatedPrice * cartItem?.quantity;
                              }, 0) *
                                2) /
                                100
                          )}
                      </b>
                    </h6>
                  </div>
                </div>
              </div>
              <div className="row my-3 px-2">
                <div className="col-md-6 col-sm 6">
                  <button className="btn border-primary text-primary w-100 mr-2" onClick={handleStepperDecrease}>
                    CANCEL
                  </button>
                </div>
                <div className="col-md-6 col-sm 6">
                  <NavLink to={"/"} className="btn border-primary text-primary cursor w-100">
                    Go To Home
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const getStepper = useCallback(() => {
    return (
      <>
        <Box sx={{ width: "100%", marginY: 3 }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {cartOrderSteps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel>
                    {label === "Order Summary" ? (orderRecord?.paymentTerms?.value === "CREDIT" ? "Quote Summary" : label) : label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </>
    );
  }, [orderRecord?.paymentTerms, activeStep]);

  return (
    <>
      <CustomizedSnackbar handleClose={handleSnackBarClose} open={openSnack} severity={severity} message={snackMessage} />
      <div className="content-body" style={{ minHeight: "884px" }}>
        <div class="container-fluid">
          <Box sx={{ width: "100%", marginY: 3 }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {cartOrderSteps.map((label) => (
                <Step key={label} disabled={label === "Payment" && orderRecord?.paymentTerms?.value === "CREDIT"}>
                  <StepLabel>
                    {label === "Order Summary" ? (orderRecord?.paymentTerms?.value === "CREDIT" ? "Quote Summary" : label) : label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {activeStep === 0 && shoppingCartItemsList()}
          {activeStep === 1 && shippingBillingCartItemsList()}
          {activeStep === 2 && shoppingPayment()}
          {activeStep === 3 && orderSummary()}
          {activeStep === 4 && orderConfiramtion()}
        </div>
      </div>

      {/* order shipping details modal */}
      {openShippingDtlsModal && (
        <OrderShippingDetails
          show={openShippingDtlsModal}
          hideModal={() => setOpenShippingDtlsModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          setLabourCharge={setLabourCharge}
        />
      )}

      {/* order billing details modal */}
      {openBillingDtlsModal && (
        <OrderBillingDetails
          show={openBillingDtlsModal}
          hideModal={() => setOpenBillingDtlsModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
          selectAddressType={selectAddressType}
          setSelectAddressType={setSelectAddressType}
          billingTypeOptions={billingTypeOptions}
          billingFrequencyOptions={billingFrequencyOptions}
        />
      )}

      {/* order information details modal */}
      {openOrderInfoModal && (
        <OrderInformation
          show={openOrderInfoModal}
          hideModal={() => setOpenOrderInfoModal(false)}
          handleSnack={handleSnack}
          orderRecord={orderRecord}
          setOrderRecord={setOrderRecord}
        />
      )}

      {/* part item details Modal  */}
      {openPartItemDtlModl && (
        <PartItemDetails
          show={openPartItemDtlModl}
          hideModal={handleClosePartItemDetailsModal}
          partItem={openPartRow}
          cartItems={cartItems}
          setCartItems={setCartItems}
          recordId={selectedPartId}
          setMouseDownDirection={setMouseDownDirection}
          handleSnack={handleSnack}
        />
      )}

      {showPaymentSuccessModal && (
        <PaymentSuccess
          show={showPaymentSuccessModal}
          hideModal={handleClosePaymentSuccessModal}
          handleSnack={handleSnack}
          cartItems={cartItems}
          labourCharge={labourCharge}
          handleStepperIncrease={handleStepperIncrease}
        />
      )}
    </>
  );
};

export default ReviewCartItem;
