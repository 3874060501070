export const deliveryTypeOptions = [
  { label: "Standard", value: "STANDARD" },
  { label: "Express", value: "EXPRESS" },
];

export const deliveryPriorityOptions = [
  { label: "Normal", value: "NORMAL" },
  { label: "Urgent", value: "URGENT" },
  //   { label: "Emergency", value: "EMERGENCY" },
  { label: "Very Urgent", value: "VERY_URGENT" },
];

export const shippingCondition = [
  { label: "Ship to Me", value: "SHIP_TO_ME" },
  { label: "Pickup", value: "PICKUP" },
];

export const shippingPointOptions = [
  { label: "Shipping Point 1", value: "SHIPPING_POINT_1" },
  { label: "Shipping Point 2", value: "SHIPPING_POINT_2" },
  { label: "Shipping Point 3", value: "SHIPPING_POINT_3" },
  { label: "Shipping Point 4", value: "SHIPPING_POINT_4" },
  { label: "Shipping Point 5", value: "SHIPPING_POINT_5" },
];

export const paymentTermsOptions = [
  { label: "Immediate", value: "IMMEDIATE" },
  { label: "Credit", value: "CREDIT" },
];

export const orderTypeOptions = [
  { label: "Emmergency", value: "EMMERGENCY" },
  { label: "Stock Order", value: "STOCK_ORDER" },
];

export const storeOptions = [
  { label: "Store 1", value: "STORE_1" },
  { label: "Store 2", value: "STORE_2" },
  { label: "Store 3", value: "STORE_3" },
  { label: "Store 4", value: "STORE_4" },
];

export const billingTypesOptions = [
  { label: "Pay Fixed Price", value: "PAY_FIXED_PRICE" },
  { label: "Pay Support", value: "PAY_SUPPORT" },
  { label: "Pay Fixed Price Fixed Time", value: "PAY_FIXED_PRICE_FIXED_TIME" },
  { label: "Fixed Price Cost Model", value: "FIXED_PRICE_COST_MODEL" },
  { label: "Performance Based", value: "PERFORMANCE_BASED" },
  { label: "Pay Service Used", value: "PAY_SERVICE_USED" },
];

export const partOrderObj = {
  partsOrderName: "",
  source: "",
  customerId: "",
  customerName: "",
  contactEmail: "",
  contactPhone: "",
  requester: "",
  customerGroup: "",
  customerSegment: "",
  make: "",
  model: "",
  serialNumber: "",
  family: "",
  smu: "",
  fleetNo: "",
  registrationNo: "",
  chasisNo: "",
  preparedBy: "",
  approvedBy: "",
  preparedOn: new Date(),
  revisedBy: "",
  revisedOn: new Date(),
  salesOffice: "",
  partsOrderDate: new Date(),
  description: "",
  reference: "",
  validity: "FIFTEEN_DAYS",
  version: "VERSION_1",
  paymentTerms: "",
  billingFrequency: "",
  billingType: "",
  deliveryType: "",
  deliveryPriority: "",
  leadTime: "",
  serviceRecipientAddress: "",
  priceDate: new Date(),
  priceMethod: "LIST_PRICE",
  currency: "",
  partsPrice: 0,
  servicePrice: 0,
  laborPrice: 0,
  miscPrice: 0,
  environmentalPrice: 0,
  tax: 0,
  costPrice: 0,
  deliveryPrice: 0,
  totalPrice: 0,
  margin: 0,
  discount: 0,
  netPrice: 0,
  adjustedPrice: 0,
  status: "PENDING_ACTIVE",
  builderId: "",
  standardJobId: "",
  kitId: "",
  builderType: "BUILDER_WITH_SPAREPART",
  dealerStore: "",
  accountName: "",
  machineSerialNumber: "",
  orderType: "",
  orderedBy: "",
  emailAddress: "",
  phoneNumber: "",
  shippingCondition: "",
  pickUpInst: "",
  shippingAddress: "",
  billingAddress: "",
  shippingPoint: "",
  demandIndicator: false,
  partialShipment: false,
  machineBreakdown: false,
  needInstallationHelp: false,
  orderAsFavorite: false,
  cartId: 0,
  updatedViaLink: false,
  orderRequisition: false,
  review: false,
  favorite: false,
  discountApproved: false,
  otherItemPrice: 0,
};

export const razorPayOrderObj = {
  amount: 0,
  currency: "INR",
  receipt: "",
};
